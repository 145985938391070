.fundraiser-campaign-junior {
  text-align: center;
  color: #000;
  margin-top: 2em;
  .content-avatar {
    box-shadow: rgb(186 186 186) 0.3em 0.2em 0.4em;
  }

  .content-detail-title {
    margin-bottom: 0;
  }
  // .content-detail-organizer {
  //   color: #f00;
  //   margin-top: 0;
  // }

  .content-detail-description {
    text-align: justify;
    width: auto;
    display: inline-block;
    margin: 0;
  }

  .youtube-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;
    margin-bottom: 8px;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .content-image-container {
    > div {
      justify-content: center;
    }

    .ant-image-img {
      object-fit: cover;
    }
  }

  .content-detail-can-info {
    font-size: xxx-large;
    .content-detail-info-second {
      font-size: x-large;
    }
    .content-detail-info-amount {
      font-size: xxx-large;
    }
    .content-detail-info-donor {
      font-size: x-large;
    }
  }

  .content-detail-info {
    font-size: xxx-large;
    margin-bottom: 0.5em;
    .content-detail-info-second {
      font-size: x-large;
    }
    .content-detail-info-amount {
      font-size: xxx-large;
      margin-top: 0.5em;
    }
    .content-detail-info-donor {
      font-size: x-large;
    }
  }
}
