.donation-link-content {
  text-align: center;
  .link-title {
    font-size: xxx-large;
    margin-top: 0;
    margin-bottom: 0;
  }
  .content-detail {
    text-align: center;
    color: #000;

    h2, h3 {
      margin-top: .5em;
      margin-bottom: .5em;
    }

    a {
      margin-top: 1em;
    }
  }

  .content-avatar {
    text-align: center;
    color: #000;
    h3 {
      margin: 0;
    }
    .avatar-logo {
      margin: 1em;
      .logo-detail-img {
        box-shadow: rgb(186 186 186) 0.3em 0.2em 0.4em;
      }
      h5 {
        margin-bottom: 0;
        font-size: x-large;
      }
      button {
        background-color: #ee1c25;
      }
    }
  }
}
