.fundraiser-campaign {
  display: flex;
  flex-direction: column;
  background: #000;

  .content-title {
    font-size: xx-large;
    text-align: center;
    color: #fff;
  }

  .content-detail {
    background: #fff;
  }

  .donation-record-list {
    float: left;
    width: 23%;
    margin-left: 15px;
  }
}
