.our-foundraiser-corporate-content {
  text-align: center;
  color: #000;
  .content-title {
    font-size: xx-large;
    .content-title-first {
      margin-bottom: 0;
    }
    .content-title-second {
      color: #ee1c25;
      font-weight: normal;
      margin-top: 0;
    }
  }

  .our-foundraiser-pagination {
    margin-top: 2em;
  }
}

@media (max-width: 575px) {
  .our-foundraiser-corporate-content {
    .our-fundraiser-avatar-component h1 {
      font-size: large;
    }

    .component-detail p {
      margin-block-start: 0;
    }
  }
}
