.junior-avatar-component {
  display: flex;
  height: 100%;

  .component-img {
    box-shadow: rgb(186 186 186) 0.3em 0.2em 0.4em;
    margin-left: 3em;
    margin-right: 3em;
  }

  .component-detail {
    position: relative;
    width: 100%;
    text-align: left;
    font-size: large;
    color: #000;
    font-weight: bold;
    padding-bottom: 48px;
    .detail-name {
      margin: 0;
      color: #000;
    }
    .detail-raised {
      color: #ee1c25;
      margin: 1em 0 0 0;
    }
    // .detail-organizer {
    //   font-weight: normal;
    //   margin: 1em 0 0 0;
    // }
    .detail-favorite {
      display: inline-flex;
      margin: 1em 0 0 0;
      .favorite-icon {
        color: red;
        font-size: 1.5em;
        padding: 0.2em 0.2em 0.14em 0.2em;
        border-radius: 50%;
        border: 2px solid #000;
      }
      .favorite-amount {
        font-weight: normal;
        line-height: 2em;
        margin: 0;
        padding-top: 0.15em;
        margin-left: 0.5em;
      }
    }

    .detail-button {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
