.fundraiser-register-default {
  display: flex;
  flex-direction: column;
  background: #000;

  .content-title {
    font-size: xx-large;
    text-align: left;
    color: #fff;
  }

  .fundraiser-register-default-detail {
    background: #fff;
    h2 {
      font-weight: normal;
    }
  }

  // .fundraiser-register-default-detail {
  //   padding-top: 5em;
  //   background: #fff;
  //   textarea,
  //   input {
  //     border: 1px solid #000;
  //     border-radius: 0;
  //   }
  //   button {
  //     border-radius: 0;
  //   }
  // }

  // .ant-form-large .ant-form-item .ant-form-item-label > label {
  //   height: 0;
  // }
}
