.sponsors-content {
  display: flex;
  flex-direction: column;
  background: #000;

  .content-title {
    font-size: xx-large;
    text-align: left;
    color: #fff;
  }

  .content-detail-description {
    background: #fff;
    text-align: center;

    h1 {
      font-size: x-large;
      text-align: center;
      padding-top: 8px;
      padding-bottom: 16px;
      border-bottom: 1px solid #000;
    }

    img {
      object-fit: contain;
      vertical-align: middle;
    }
  }
}

@media (max-width: 575px) {
  .sponsors-content {
    .content-detail-description img {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
