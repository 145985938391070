.fundraiser-reset-content {
  display: flex;
  flex-direction: column;
  background: #000;

  .content-title {
    font-size: xx-large;
    text-align: left;
    color: #fff;
  }

  .fundraiser-reset-detail {
    padding-top: 1em;
    background: #fff;
  }
}
