.header-content {
  background-color: #fff;
  height: auto;
  padding: 0;
  .content-top {
    text-align: center;
    color: #fff;
    height: auto;

    .ant-image-img {
      max-height: 120px;
    }

    .content-top-image {
      padding: 24px;

      img {
        max-height: 80px;
        object-fit: contain;
      }
    }

    .lang-btn {
      button {
        font-weight: bold;
        color: #000;
      }
    }
  }

  .logout-button {
    width: 100%;
    max-width: 280px;
    font-weight: bold;
    background-color: #ee1c25;
    border: none;
    color: #fff !important;
    .ant-wave {
      display: none;
    }
  }
  .login-button {
    width: 100%;
    max-width: 280px;
    font-weight: bold;
    background-color: #000;
    border: none;
    color: #fff !important;
    font-size: medium;
    .ant-wave {
      display: none;
    }
  }

  .ant-menu {
    > .ant-menu-item-selected,
    .ant-menu-submenu-selected {
      background-color: #000;

      &:hover {
        background-color: #000;
      }
    }
  }

  .header-mobile-container {
    border-top: 1px solid #999;
    border-bottom: 1px solid #999;

    .button-right {
      height: 48px;
      border-radius: 0;

      &:hover {
        color: #000;
      }
    }
  }
}

.header-drawer {
  .ant-drawer-header {
    padding: 0;
  }

  .ant-drawer-title {
    button {
      height: 48px;
    }

    .logout-button {
      width: 100%;
      border-radius: 0;
      border-color: transparent;
      background: #ee1c25;
      color: #fff;
      text-align: left;

      &:hover {
        color: #fff;
        border-color: transparent;
      }
    }

    .button-right {
      border-radius: 0;
      background: #000;
      color: #fff;

      &:hover {
        background: #000;
        color: #fff;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .ant-drawer-body {
    padding: 0;

    .ant-menu {
      background: #000;
    }

    .ant-menu-item {
      background-color: #fff;
      color: #000;
      margin: 1px;
      margin-left: 0;
      margin-right: 0;
      width: 100%;

      &:active {
        background: #000;
      }
    }

    .ant-menu-submenu-title {
      background-color: #fff;
      color: #000;
      margin: 1px;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }
  }

  .ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover,
  .ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item:not(.ant-menu-item-selected):hover {
    background: #ccc;
    color: #000;
  }
}

.ant-menu-submenu {
  .ant-menu-item {
    background-color: #fff !important;
    color: #000 !important;
  }
}

.ant-menu-dark > .ant-menu {
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #000;
  }
  .ant-menu-submenu-title {
    color: #000;
  }
}
