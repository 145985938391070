.about-us-component-detail {
  text-align: left;

  h2 {
    color: #414042;
  }
  h4 {
    color: #20386e;
    .anticon {
      margin-right: 10px;
    }
    word-break: break-all;
  }

  a {
    color: #20386e !important;
  }
}
