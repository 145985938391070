.more-content {
  display: flex;
  flex-direction: column;
  background: #000;

  .content-title {
    font-size: xx-large;
    text-align: left;
    color: #fff;
  }

  .content-detail {
    background: #fff;
    text-align: center;
  }
}
