.canstruction-exhibition {
  display: flex;
  flex-direction: column;
  background: #000;
  h1 {
    color: #ee1c25;
    margin-top: 1em;
  }

  .content-title {
    font-size: xx-large;
    text-align: left;
    color: #fff;
  }

  .content-detail-description {
    background: #fff;
    text-align: left;
    padding-top: 1em;
    h2 {
      font-weight: normal;
    }

    .ant-image-img {
      max-width: 120px;
    }

    .youtube-container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      overflow: hidden;
      margin-bottom: 8px;

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
