.our-fundraiser-avatar-component {
  position: relative;
  height: 100%;
  text-align: center;
  color: #000;
  padding-bottom: 32px;
  .component-avatar {
    box-shadow: rgb(186 186 186) 0.3em 0.2em 0.4em;
  }

  .detail-button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
