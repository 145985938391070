.canstruction-junior {
  display: flex;
  flex-direction: column;

  color: #fff;
  text-align: center;
  .content-title-container {
    background: #000;

    .content-title {
      font-size: xx-large;
    }
  }
  .content-nav {
    text-align: center;

    .nav-title {
      color: #000;
      background-color: #fff;
      border: 2px solid #000;
      border-right: 0;
    }
    .active {
      background: #ee1c25;
      color: #fff;
    }
  }
  .junior-component {
    padding: 2em 0 2em 0;
  }
}

@media (max-width: 575px) {
  .content-nav h1 {
    font-size: x-large;
  }
}
