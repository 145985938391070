.about-us-canstruction {
  display: flex;
  flex-direction: column;
  background: #000;
  h1 {
    color: #ee1c25;
    margin-top: 1em;
  }

  .content-title {
    font-size: xx-large;
    text-align: left;
    color: #fff;
  }

  .content-detail-description {
    background: #fff;
    text-align: left;
    h2 {
      font-weight: normal;
    }
  }
}
