.fundraiser-home {
  display: flex;
  flex-direction: column;

  color: #fff;
  text-align: center;

  .content-title-container {
    background: #000;
    .content-title {
      font-size: xx-large;
      text-align: left;
      color: #fff;
    }
  }

  .content-nav {
    text-align: center;
    background: #fff;
    border-bottom: 2px solid #000;

    .nav-title {
      color: #000;
      background-color: #fff;
      border: 2px solid #000;
      border-top: 0;
      border-bottom: 0;

      & + .nav-title {
        border-left: 0;
      }
    }
    .active {
      background: #ee1c25;
      color: #fff;
    }
  }
}

@media (max-width: 575px) {
  .fundraiser-home {
    .content-nav h1 {
      font-size: large;
    }
  }
}
