.donation-payment-content {
  text-align: center;
}

.payment-modal {
  .ant-image-img {
    object-fit: contain;
  }
  .payme-code-img {
    opacity: 0.1;
  }
  .close-out-icon {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    font-size: 150px;
    margin-top: 220px;
  }
}
