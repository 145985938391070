.fundraiser-account-information {
  .ant-card .ant-card-head {
    border: 0;
  }
  .ant-card:not(.ant-card-bordered) {
    box-shadow: none;
  }

  button,
  .ant-btn-text:disabled {
    color: #f00;
  }
  .btn-white-font {
    color: #fff;
  }
}
