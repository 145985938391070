.fundraiser-home-component {
  .component-img {
    box-shadow: rgb(186 186 186) 0.3em 0.2em 0.4em;
  }

  .component-detail-individual {
    text-align: center !important;
    flex-direction: none;
    justify-content: center;
    display: block;
    height: auto;
  }

  .component-detail-corporate {
    text-align: left;
    flex-direction: column;
    justify-content: center;
    display: flex;
    height: 10em;
  }

  .component-detail {
    font-size: large;
    color: #000;
    font-weight: bold;
    margin-left: 1em;
    text-align: left;

    .detail-name {
      margin: 0;
      color: #000;
    }
    .detail-raised {
      color: #ee1c25;
      margin: 1em 0 0 0;
    }
    // .detail-organizer {
    //   font-weight: normal;
    //   margin: 1em 0 0 0;
    // }
    .detail-favorite {
      display: inline-flex;
      margin: 1em 0 0 0;
      .favorite-icon {
        color: red;
        font-size: 1.5em;
        padding: 0.2em 0.2em 0.14em 0.2em;
        border-radius: 50%;
        border: 2px solid #000;
      }
      .favorite-amount {
        font-weight: normal;
        line-height: 2em;
        margin: 0;
        padding-top: 0.15em;
        margin-left: 0.5em;
      }
    }

    .detail-button {
      margin-top: 1em;
    }
  }
}
