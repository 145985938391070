.mega-content {
  .content-title {
    margin-left: auto;
    margin-right: auto;
    border-left: 0.5em solid red;
    text-align: center;
    padding-left: 4px;
    padding-right: 4px;

    h1:first-child {
      margin: 0;
      font-size: xxx-large;
      font-weight: normal;
      letter-spacing: 0.02em;
    }
    h1:last-child {
      margin: 0;
      font-size: xx-large;
      letter-spacing: 0.01em;
      margin-top: 4px;
    }
  }

  .content-detail {
    margin-top: 16px;

    h2 {
      text-align: justify;
      margin-top: 0;
    }

    .content-detail-image-shadow {
      background: #fff;
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.5;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .content-bottom {
    color: #9d9d9d;
  }
}

@media (max-width: 575px) {
  .mega-content {
    .content-title {
      h1:first-child {
        font-size: large !important;
      }
      h1:last-child {
        font-size: small !important;
      }
    }

    h3 {
      font-size: large !important;
    }

    .content-detail h2 {
      font-size: medium !important;
    }

    .progress-percent {
      font-size: 3em !important;
    }

    .content-bottom {
      font-size: small !important;
    }
  }
}
