html,
body {
  margin: 0;
  touch-action: pan-x pan-y;
}

body {
  // ��ʽ����

  .learn-more-button {
    background: #ee1c25;
    color: #fff;
    font-weight: bold;
    width: 100%;
    max-width: 120px;
  }
  .link-button {
    width: 100%;
    max-width: 150px;
  }
  .support-button {
    width: 100%;
    max-width: 200px;
  }

  .wide-button {
    width: 100%;
    max-width: 150px;
  }

  button,
  ul,
  input,
  a {
    outline: none !important;
  }

  .ant-spin-container,
  .ant-spin-nested-loading {
    height: 100%;
  }
  textarea,
  input {
    border: 1px solid rgba(0, 0, 0, 0.88) !important;
    border-radius: 0 !important;
  }

  .ant-layout {
    background: #fff;
    min-height: 100%;
  }

  .app-layout-spin {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

.ant-divider {
  background: rgba(0, 0, 0, 0.88);
}

.ant-pagination {
  .ant-pagination-item {
    font-size: 18px;
  }
}

.ant-pagination .ant-pagination-item-active {
  border: 0;
  border-radius: 0;
  border-bottom: 4px #ee1c25 solid;

  a {
    color: inherit;
    font-weight: normal;
  }

  &:hover {
    border: 0;
    border-radius: 0;

    a {
      color: inherit;
      border-bottom: 4px #ff7875 solid;
    }
  }
}

.ant-btn-primary.ant-btn-dangerous {
  background: #ee1c25;
}

.ant-carousel .slick-dots {
  align-items: baseline;

  li {
    width: 10px;
    height: 10px;

    &.slick-active button {
      border: 1px solid #ee1c25;
      height: 16px;
    }

    button {
      height: 10px;
      border-radius: 50%;
      overflow: hidden;
      opacity: 1;
      background: #ee1c25;
      border: 0;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.ant-form-item-label label {
  white-space: normal;
}

.crop-multiline-3 {
  display: -webkit-inline-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: justify;
}

.crop-multiline-4 {
  display: -webkit-inline-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: justify;
}

.html-content-detail {
  white-space: pre-line;
}

#root,
body,
html {
  height: 100%;
}

.container {
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;

  img {
    max-width: 100%;
  }
}

@media (min-width: 476px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 768px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 992px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (min-width: 476px) {
  .container {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}

@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}

/* mobile version  */
@media (min-width: 576px) {
  .mobile-visible {
    display: none;
  }
}

@media (max-width: 575px) {
  .content-top {
    .content-top-image {
      padding: 12px !important;
      img {
        height: 60px !important;
      }
    }

    .ant-divider {
      height: 0.2em !important;
      margin: 0 !important;
    }
  }

  .mobile-hidden {
    display: none;
  }

  .ant-divider {
    margin: 12px 0 !important;
  }

  .content-title {
    font-size: medium !important;
    font-weight: normal;
  }

  .ant-select {
    width: 80px !important;
  }

  .container {
    :not(.ant-avatar) > img {
      height: auto !important;
    }
  }
}
