.canstruction-junior-content {
  text-align: center;
  .content-title {
    text-align: center;
    color: #000;
    font-size: xx-large;

    h3, h6 {
      margin: .5em;
      margin-top: 0;
    }
    h6 {
      margin-bottom: 2em;
      font-weight: normal;
    }
  }
}
