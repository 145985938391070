.fundraiser-campaign-corporate {
  text-align: center;
  color: #000;
  margin-top: 2em;
  .content-avatar {
    box-shadow: rgb(186 186 186) 0.3em 0.2em 0.4em;
  }

  .content-detail-description {
    text-align: justify;
    width: auto;
    display: inline-block;
    margin: 0;
  }

  .content-detail-info {
    font-size: xxx-large;
    margin-bottom: 0.5em;
    .content-detail-info-second {
      font-size: x-large;
    }
    .content-detail-info-amount {
      font-size: xxx-large;
      margin-top: 0.5em;
    }
    .content-detail-info-donor {
      font-size: x-large;
    }
  }
}
