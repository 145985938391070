.fundraiser-campaign-individual {
  text-align: center;
  color: #000;
  margin-top: 2em;

  .content-detail-description {
    text-align: justify;
    width: auto;
    display: inline-block;
    margin: 0;
  }

  .content-detail-info {
    font-size: xxx-large;
    margin-bottom: .5em;
    .content-detail-info-second {
      font-size: x-large;
    }
    .content-detail-info-amount {
      font-size: xxx-large;
      margin-top: .5em;
    }
    .content-detail-info-donor {
      font-size: x-large;
    }
  }
}
