// antd menu优化样式
.ant-menu .ant-menu-item-divider {
  border-top: 1px solid #fff;
  margin: 0;
}
.ant-menu-light.ant-menu-inline .ant-menu-item,
.ant-menu-light > .ant-menu.ant-menu-inline .ant-menu-item {
  border-radius: 0;
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light > .ant-menu .ant-menu-item-selected {
  background: #ee1c25;
  color: #fff;
}

.ant-menu-light
  .ant-menu-item:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ):hover,
.ant-menu-light
  > .ant-menu
  .ant-menu-item:not(.ant-menu-item-selected):not(
    .ant-menu-submenu-selected
  ):hover,
.ant-menu-light
  .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected)
  > .ant-menu-submenu-title:hover,
.ant-menu-light
  > .ant-menu
  .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected)
  > .ant-menu-submenu-title:hover {
  color: #fff;
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
.ant-menu-light
  > .ant-menu
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  color: #fff;
}

.fundraiser-portal {
  display: flex;
  flex-direction: column;
  background: #000;

  .content-title {
    font-size: xx-large;
    text-align: left;
    color: #fff;
  }

  .ant-layout-sider-children:before {
    display: block;
    content: "";
    position: absolute;
    left: -1000%;
    top: 0;
    right: 0;
    bottom: 0;
    background: #000;
    z-index: 0;
  }

  .mobile-header {
    padding: 0;
  }
}
