.campaign-date-content {
  background-color: #ee1c25;
  padding: 1.5em 0;
  text-align: center;
  color: #fff;
}

@media (max-width: 575px) {
  .ant-space-gap-col-large {
    column-gap: 12px;
  }

  .campaign-date-content {
    padding: 1em 0;

    h1 {
      font-size: 2em !important;
    }
    h4 {
      font-size: 1em !important;
    }
    h3 {
      font-size: 1.2em !important;
    }
  }

  .ant-progress {

    .ant-image {
      width: 100px !important;

      img {
        width: 100px;
        height: 100px;
      }
    }
  }
}
