.fundraiser-history {
  background: #fff;

  .ant-card .ant-card-head {
    border: 0;
  }
  .ant-card:not(.ant-card-bordered) {
    box-shadow: none;
  }
}
